@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap');




body {
  margin: 0;
  font-family: 'Cabin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
  color: red;
}

.swiper-pagination-bullet-active {
  background-color:#ea580c !important;
}

@media screen and (max-width: 767px) {
  .px-6, .pl-8, .pr8 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .right-8 {
    right: 10px;
  }

  .link {
    text-decoration: underline;
    color: #ea580c;
  }

}